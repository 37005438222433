<template>
    <div v-loading="loading">
        <el-row class="clooect-global-hd">
            <el-col :span="14">
                <el-radio-group v-model="radio" @change="changeType">
                    <el-radio :label="0">全部项目</el-radio>
                    <el-radio :label="1">竞价项目</el-radio>
                    <el-radio :label="2">比选项目</el-radio>
                    <el-radio :label="3">日薪项目</el-radio>
                </el-radio-group>
            </el-col>

            <el-col :span="10">
                <el-form>
                    <el-form-item>
                        <el-input placeholder="请输入项目名称" v-model="keywords"></el-input>
                        <el-button type="primary" @click="search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <ul class="collect-task" v-if="value && value.length>0">
            <li class="collect-info" v-for="(item,index) in value" :key="index">
                <div class="collect-hd">
                    <span class="status">{{item.status_value}}</span>
                    <h4 class="title" @click="detail(item.tk)">{{item.task_name}}</h4>
                </div>
                <div class="guide">
                    <span
                        class="number fuzhi"
                        style="cursor:pointer"
                        :data-clipboard-text="item.sn"
                        @click="copy"
                    >
                        编号：{{item.sn}}
                        <i class="jk-icon-fuzhi"></i>
                    </span>

                    <strong
                        class="task-type jingjia"
                        v-if="item.task_type == 1"
                        v-text="item.task_type_value"
                    ></strong>

                    <strong
                        class="task-type bixuan"
                        v-if="item.task_type == 2"
                        v-text="item.task_type_value"
                    ></strong>

                    <strong
                        class="task-type rixin"
                        v-if="item.task_type == 3"
                        v-text="item.task_type_value"
                    ></strong>
                </div>

                <div class="collect-ftr">
                    <el-button @click="open(item.id)">
                        <i class="jk-icon-yishoucang"></i>已收藏
                    </el-button>
                    <div class="tips">
                        <p>
                            发布时间：
                            <em>{{item.approve_time}}</em>
                        </p>
                        <p>
                            报名截止时间：
                            <em>{{item.end_time_value}}</em>
                        </p>
                    </div>
                </div>
            </li>
        </ul>
        <div v-else style="width:100%;height:400px;line-height:330px;text-align:center;color:#999;">暂无相关数据</div>

        <el-dialog
            class="blue-top-border"
            title="取消收藏"
            :visible.sync="collectDialog"
            width="400px"
            center
        >
            <div class="tips-txt">确定要取消收藏该项目吗？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="collectDialog = false">返 回</el-button>
                <el-button type="primary" @click="del()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "Task",
    props: ['value'],
    data() {
        return {
            radio: 0,//筛选类型
            collectDialog: false,//取消收藏对话框
            keywords: '',//搜索关键字
            id: '',//收藏内容id
            loading: false,

        };
    },
    methods: {
        //切换类型
        changeType(type) {
            this.$emit('type', type);
        },

        //搜索
        search() {
            this.$emit('search', this.keywords);
        },

        //取消收藏
        del() {
            this.collectDialog = false;
            this.$emit('del', this.id);
        },

        //打开取消收藏对话框
        open(id) {
            this.collectDialog = true;
            this.id = id;
        },

        //复制功能
        copy() {
            var clipboard = new this.Clipboard(".fuzhi");
            clipboard.on("success", e => {
                this.$message({
                    message: "复制成功",
                    type: "success"
                });
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                clipboard.destroy();
            });
        },

        //项目详情
        detail(val) {
            this.$router.push('/task/detail?tk=' + val);
        }
    },
};
</script>

