<template>
    <div v-loading="loading">
        <el-row class="clooect-global-hd">
            <el-col :span="14">
                <el-radio-group v-model="radio" @change="changeType">
                    <el-radio :label="0">全部</el-radio>
                    <el-radio :label="1">只看工程极客</el-radio>
                    <el-radio :label="2">只看雇主</el-radio>
                </el-radio-group>
            </el-col>

            <el-col :span="10">
                <el-form>
                    <el-form-item>
                        <el-input placeholder="请输入用户名称" v-model="keywords"></el-input>
                        <el-button type="primary" @click="search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <ul class="attention-list" v-if="value && value.length>0">
            <li class="attention-info" v-for="(item,index) in value" :key="index">
                <el-row>
                    <el-col :span="6">
                        <div class="avatar">
                            <img :src="util.reImg(item.avatar)" />
                        </div>
                        <p class="tips" @click="goUserArticleCenter(item.uk)">查看动态</p>
                    </el-col>

                    <el-col :span="18">
                        <h4 class="name">{{item.name}}</h4>
                        <p class="guide">
                            <span v-if="item.user_type==1">{{item.attestation_type_value}}极客</span>
                            <span v-if="item.user_type==2">{{item.attestation_type_value}}雇主</span>
                            <span>{{item.area_value}}</span>
                        </p>

                        <el-row>
                            <el-button @click="open(item.id)">
                                <i class="jk-icon-yiguanzhu"></i>已关注
                            </el-button>

                            <el-button @click="sendMessage(item.uk)">
                                <i class="jk-icon-faxiaoxi"></i>联系TA
                            </el-button>
                        </el-row>
                    </el-col>
                </el-row>
            </li>
        </ul>

        <div v-else style="width:100%;height:400px;line-height:330px;text-align:center;color:#999;">暂无相关数据</div>

        <el-dialog
            class="blue-top-border"
            title="取消关注"
            :visible.sync="userListDialog"
            width="400px"
            center
        >
            <div class="tips-txt">确定要取消关注该用户吗？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="userListDialog = false">返回</el-button>
                <el-button type="primary" @click="del">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: "User",
    props: ['value'],
    data() {
        return {
            loading: false,
            userList: [],//用户列表
            radio: 0,// type
            keywords: "",
            userListDialog: false,
            id: '',
        };
    },
    methods: {
        sendMessage(uk) {
            //存储当前值
            sessionStorage.setItem("currentPageUrl", document.location.pathname + document.location.search);
            this.$router.push("/user/message/sendmsg?uk=" + uk);
        },

        //切换类型
        changeType(type) {
            this.$emit('type', type);
        },

        //打开取消收藏对话框
        open(id) {
            this.userListDialog = true;
            this.id = id;
        },

        //取消收藏
        del() {
            this.userListDialog = false;
            this.$emit('del', this.id);
        },

        //搜索
        search() {
            this.$emit('search', this.keywords);
        },

        //个人中心详情
        goUserArticleCenter(uk) {
            this.$router.push("/firm/index?uk=" + uk);
        }
    },
};
</script>
<style lang="less">
.attention-list .tips {
    cursor: pointer !important;
}
</style>

