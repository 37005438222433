<template>
    <div class="collect-panel" v-loading="loading">
        <div class="white-bg">
            <el-tabs v-model="activeName" @tab-click="tabChange">
                <el-tab-pane label="我关注的人" name="first">
                    <User v-model="listData" @type="type" @search="search" @del="del"></User>
                </el-tab-pane>

                <el-tab-pane label="我收藏的资源" name="second">
                    <Article v-model="listData" @type="type" @search="search" @del="del"></Article>
                </el-tab-pane>

                <el-tab-pane label="我收藏的项目" name="third">
                    <Task v-model="listData" @type="type" @search="search" @del="del"></Task>
                </el-tab-pane>
            </el-tabs>

            <pagination
                v-if="listData.length>0"
                :pageSize="pageSize"
                :currentPage="currentPage"
                :pageTotal="pageTotal"
                @current-change="newPage"
            ></pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import User from "./module/User.vue";
import Task from "./module/Task.vue";
import Article from "./module/Article.vue";
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "collect",
    metaInfo() {
        return {
            title: "关注与收藏 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {
        User, //关注
        Task, //收藏的项目
        Article, //收藏的文章
        Pagination//分页
    },
    data() {
        return {
            activeName: "first",
            listData: '',
            currentPage: 1,
            pageSize: 12,
            pageTotal: 0,
            loading: false,
            c_type: 0,
            keyword: '',
            typeVal: 1,
        };
    },
    methods: {
        //分页
        newPage(page) {
            let _this = this;
            _this.currentPage = page;
            _this.loadListData();
        },
        //获取列表数据
        loadListData() {
            let _this = this;
            _this.loading = true;
            _this.post(
                '/user/user/collect_list',
                {
                    type: _this.typeVal,//收藏类型（1.关注的人；2.收藏的文章；3.收藏的项目）
                    c_type: _this.c_type,//关注的人:【1工程极客；2.雇主】
                    page: _this.currentPage,
                    size: _this.pageSize,
                    keyword: _this.keyword//搜索昵称
                },
                function (res) {
                    _this.loading = false;
                    if (200 == res.code) {
                        _this.listData = res.data.list;
                        _this.pageTotal = res.data.count;
                    } else {
                        _this.$message.error('获取失败');
                    }
                }
            );
        },

        //切换资源类型
        type(val) {
            this.currentPage = 1;
            this.c_type = val;
            this.loadListData();
        },

        //搜索
        search(val) {
            this.currentPage = 1;
            this.keyword = val;
            this.loadListData();
        },

        //切换tabs事件
        tabChange(e) {
            switch (e.index) {
                case '0':
                    this.typeVal = 1;
                    break;
                case '1':
                    this.typeVal = 2;
                    break;
                case '2':
                    this.typeVal = 3;
                    break;
                default:
                    break;
            }
            sessionStorage.setItem('collect_tabs_index', this.activeName);
            sessionStorage.setItem('collect_tabs_type', this.typeVal);
            this.c_type = 0;
            this.currentPage = 1;
            this.loadListData();
        },

        //执行取消收藏
        del(val) { 
            let _this = this;
            _this.loading = true;
            _this.post(
                "/user/user/del_collect",
                {
                    id: val
                },
                function (res) {
                    _this.loading = false;
                    if (200 == res.code) {
                        _this.loadListData();//刷新数据
                        _this.$message.success(res.msg);
                        return false;
                    }
                    _this.$message.error(res.msg);
                }
            );
        },
    },
    created() {
        let collect_tabs_index = sessionStorage.getItem('collect_tabs_index');
        let collect_tabs_type = sessionStorage.getItem('collect_tabs_type');
        if (collect_tabs_index) {
            this.activeName = collect_tabs_index;
            this.typeVal = collect_tabs_type;
            this.loadListData();
        } else {
            this.typeVal = 1;
            this.loadListData();
        }
    }
};
</script>
<style lang="less">
@import "../../../styles/collect.less";
</style>


