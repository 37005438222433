<template>
    <div>
        <el-row class="clooect-global-hd">
            <el-col :span="14">
                <el-radio-group
                    v-model="radio"
                    @change="changeType"
                >
                    <el-radio :label="0">全部</el-radio>
                    <el-radio :label="1">官方资源</el-radio>
                    <el-radio :label="2">网友资源</el-radio>
                </el-radio-group>
            </el-col>

            <el-col :span="10">
                <el-form>
                    <el-form-item>
                        <el-input
                            placeholder="请输入资源关键字"
                            v-model="keywords"
                        ></el-input>
                        <el-button
                            type="primary"
                            @click="search"
                        >搜索</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <ul
            class="article-items collect_article_items"
            v-if="value && value.length>0"
        >
            <li
                v-for="(item,index) in value"
                :key="index"
                class="item nav-link"
                tag="li"
            >
                <div class="pic">
                    <img :src="util.reImg(item.img)" />
                </div>

                <div class="item-cont">
                    <div class="hd">
                        <em
                            class="guangfang"
                            v-if="item.is_official"
                        >
                            <img :src="$img.guanfang" />官方
                        </em>
                        <h4
                            class="title"
                            @click="detail(item.article_id)"
                        >
                            <i :class="{'jk-icon-huo':item.is_hot == 1}"></i>
                            {{item.title}}
                        </h4>
                    </div>

                    <div class="bd">
                        <div class="tag">
                            <span
                                class="jinghua"
                                v-if="item.cate_id"
                                v-text="item.cate_id.value"
                            ></span>
                        </div>
                        <div class="download">
                            <span>
                                <em
                                    class="orange"
                                    v-if="!item.download_point_count"
                                >免费下载</em>
                            </span>
                            <span v-if="item.download_point_count">
                                下载所需点券：
                                <em class="orange">{{item.download_point_count}}点</em>
                            </span>
                            <span>
                                下载次数：
                                <em class="blue">{{item.download_count}}次</em>
                            </span>
                        </div>
                    </div>

                    <div class="ftr">
                        <span>
                            <i class="jk-icon-liulanliang"></i>
                            {{item.view_count}}
                        </span>
                        <span>
                            <i class="jk-icon-dianzanshu"></i>
                            {{item.like_count}}
                        </span>
                        <time class="date">{{item.create_time}}</time>
                    </div>
                </div>
            </li>
        </ul>
        <div
            v-else
            style="width:100%;height:400px;line-height:330px;text-align:center;color:#999;"
        >暂无相关数据</div>
    </div>
</template>
<script>
export default {
    name: "Article",
    props: ['value'],
    data() {
        return {
            radio: 0,
            keywords: "",
        };
    },
    methods: {
        //收藏
        keep(uk) {

        },

        //切换类型
        changeType(type) {
            this.$emit('type', type);
        },

        //搜索
        search() {
            this.$emit('search', this.keywords);
        },

        //文章详情
        detail(val) {
            this.$router.push('/article/detail/' + val);
        }
    },
};
</script>

<style lang="less">
.collect_article_items {
    .item {
        .item-cont {
            width: 770px !important;
        }
        .download {
            padding: 0;
        }
    }
}
.article-items .hd .title:hover {
    color: #587ddc;
    cursor: pointer;
}
</style>

